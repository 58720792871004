<template>
  <div class="wet-section wet-main">
    <div class="container custom">
      <div class="wet-main-content">
        <h1>{{str.sections.title_vacation}}</h1>
        <div @click="redirect( 'vacation')" style="cursor: pointer;">
          <reactive-picture
            v-if="mainImagesThird.image.sizes"
            :width=1536
            :height=780
            :size-collection-type="wpImageSizeCollectionType.EXTRA_BIG"
            :image="mainImagesThird.image"
          />
          <img
            v-else
            :width=1536
            :height=780
          />
          <div class="wet-info-item-button text-center mt-3">
            <p class="wet-button wet-button-md">
              {{mainImagesThird.image_link_text}}
            </p>
          </div>
        </div>
        <div class="wet-main-description video_box">
          {{str.sections.description_vacation}}
        </div>
        <br/>
        <div v-html="main_title" class="wet-section-title text-center mob-hide"></div>
        <div class="wet-main-items">
          <div class="row align-items-end">
            <template
              v-for="(item, id) in mainImagesFirstTwo"
            >
              <h2
                class="col-md-6 col-sm-6 desc-hide"
                :key="id"
              >
                {{id === 0 ? str.sections.main_title_mobile_drought :
                str.sections.main_title_mobile_rain }}
              </h2>
              <div
                class="col-md-6 col-sm-6"
                :key="10+id"
              >
                <div
                  @click="redirect(id === 0 ? 'drought' : 'rain')"
                  class="wet-main-item"
                  style="cursor: pointer;"
                >
                  <reactive-picture
                    v-if="item.image.sizes"
                    :width=500
                    :height=400
                    :size-collection-type="wpImageSizeCollectionType.SMALL"
                    :image="item.image"
                    class="wet-main-item-inner"
                  />
                  <img
                    v-else
                    :width=500
                    :height=400
                  />
                  <reactive-picture
                    :width=500
                    :height=400
                    :size-collection-type="wpImageSizeCollectionType.SMALL"
                    :image="item.image_hover"
                    class="wet-main-item-hover"
                  />
                </div>
                <template v-if="id === 0">
                  <div
                    class="video_box"
                    :key="20+id"
                    @click="showDroughtVideo=true"
                  >
                    <button :data-video_id="getVideoIdTag(id)"
                            data-toggle="modal"
                            data-target="#videoModal" class="video_button button">
                      <img
                        :src="videoIcon"
                        alt="video icon"
                        title="video icon"
                        width=25
                        height="25"
                        class="mr-2"
                      />
                      <div class="video_text">
                        {{id === 0 ? videoTextDrought : videoTextRain}}
                      </div>
                    </button>
                  </div>
                </template>
                <template v-if="id === 1">
                  <div
                    class="video_box"
                    :key="20+id"
                  >
                      <div class="video_text">
                        {{id === 0 ? videoTextDrought : videoTextRain}}
                      </div>
                  </div>
                  <input type="hidden" :id="getVideoIdTag(id)"
                         :name="getVideoIdTag(id)"
                         :value="id === 0 ? videoIdDrought : videoIdRain" />
                </template>
                <div class="wet-info-item-button text-center">
                  <p :id="`wet-button-${id}`"
                     class="wet-button wet-button-md"
                     @click="redirect(id === 0 ? 'drought' : 'rain')"
                  >
                    {{item.image_link_text}}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="wet-main-description" v-html="str.sections.main_description">
        </div>
        <modalComponent
          :showed.sync="showDroughtVideo"
          modtitle=""
          id="videoModal"
        >
          <videoComponent :video="droughtVideo" />
        </modalComponent>
      </div>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import partnerCodeMixin from '@/mixins/partner-code';
import changeLang from '@/mixins/changeLang';
import wpSection from '@/enums/wpSection';
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';
import { sections } from '@/enums/stringIndices';

export default {
  mixins: [common, partnerCodeMixin, changeLang],
  components: {
    modalComponent: () => import(/* webpackChunkName: "modalComponent" */ '@/components/modalComponent.vue'),
    videoComponent: () => import(/* webpackChunkName: "videoComponent" */ '@/components/embedVideo.vue'),
    reactivePicture: () => import(/* webpackChunkName: "reactiveComponent" */ '@/components/ui/reactive-pictureUi.vue'),
  },
  props: {
    beforeOrder: {
      type: Function,
      default: () => Promise.resolve(),
    },
  },
  data() {
    return {
      wpImageSizeCollectionType,
      current: '',
      crops: [],
      invalidLocation: false,
      screenWidth: 0,
      str: {
        sections,
      },
      showDroughtVideo: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    videoIdDrought() {
      return this.str.sections?.[this.getVideoIdTag(0)];
    },
    videoIdRain() {
      return this.str.sections?.[this.getVideoIdTag(0)];
    },
    videoTextRain() {
      return this.str.sections.video_text_rain;
    },
    videoTextDrought() {
      return this.str.sections.video_text_drought;
    },
    mainImagesFirstTwo() {
      return this.str.sections.main_images.slice(0, 2);
    },
    mainImagesThird() {
      return this.str.sections.main_images[2];
    },
    main_title() {
      return `<h1>${this.str.sections.main_title}</h1>`;
    },
    videoIcon() {
      return this.str.sections.video_icon;
    },
    droughtVideo() {
      return {
        youtube: this.str.sections.video_youtube_id_drought,
      };
    },
  },
  methods: {
    getVideoIdTag(id) {
      return id === 0 ? 'video_youtube_id_drought'
        : 'video_youtube_id_rain';
    },
    redirect(page) {
      this.$router.push({ name: page, params: { ...this.$route.params } });
    },
    init() {
      this.getWordPressStrings(
        [
          wpSection.SECTIONS,
          wpSection.COMMON_APP,
        ],
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wet-section-hotel {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:
      radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,.5) 70%, rgba(0,0,0,.8) 100%);
  }

  &>.container {
    position: relative;
  }
}

.wet-section-box-rounded {
  border-radius: var(--border-radius);
  background-color: #3B384D;
  padding: 1.5rem;
}
</style>
